import { useQuery } from '@apollo/client';
import React from 'react';
import { Flex, Heading } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import getProductsList from '../graphql/queries/getProductsList.graphql';
import Loader from './Loader';
import TrainingThumbnail from './TrainingThumbnail';
import PaginatedView from './PaginatedView';

export default function DocumentsContent({ page }) {
  const { data, loading } = useQuery(getProductsList, {
    variables: { type: 'document', page },
  });
  if (loading || !data) {
    return <Loader />;
  }
  const onlineTrainings = data.getProductsList.list;
  return (
    <PaginatedView
      linkName={'dokumenty/strona'}
      pagesCount={data.getProductsList.pages}
      currentPage={page}
    >
      <Heading
        marginLeft={20}
        mb={[25, 50]}
        fontSize={[24, 32]}
        fontFamily={'Oswald'}
        color={'text'}
      >
        Dokumenty
      </Heading>
      <Flex
        flexDirection={'row'}
        color={'text'}
        flexWrap={'wrap'}
        variant={'wrapper'}
      >
        {onlineTrainings ? (
          onlineTrainings.map((training) => (
            <TrainingThumbnail
              data={training}
              key={training._id}
              isRedirectingExt={true}
            />
          ))
        ) : (
          <Loader />
        )}
      </Flex>
    </PaginatedView>
  );
}

DocumentsContent.propTypes = {
  page: PropTypes.number.isRequired,
};
