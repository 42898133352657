import React from 'react';
import PropTypes from 'prop-types';
import DocumentsContent from '../../../components/DocumentsContent';
import Layout from '../../../components/Layout';

const DocumentsPage = ({ page }) => {
  return (
    <Layout>
      <DocumentsContent
        page={Number.isNaN(Number(page)) || Number(page) < 0 ? 0 : Number(page)}
      />
    </Layout>
  );
};

DocumentsPage.propTypes = {
  page: PropTypes.string.isRequired,
};

DocumentsPage.defaultProps = {};

export default DocumentsPage;
